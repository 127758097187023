import xpath from 'xpath';

const defaultOptions = {
    soapServer: 'https://sgdctestsim.colonizacion.com.uy:8181',
    soapUri: '/SIM2WS/GestionInventarioService'
};
/*
const defaultOptions = {
    soapServer: process.env.VUE_APP_SOAP_SERVER,
    soapUri: process.env.VUE_APP_SOAP_URI
};*/

const soap = {
    install: (Vue, options) => {
        let userOptions = { ...defaultOptions, ...options };

        console.log('SOAP_SERVER: ' + userOptions.soapServer);
        console.log('SOAP_URI: ' + userOptions.soapUri);

        Vue.prototype.$ping = function(){
            return this.axios.post(userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:ping>' +
                '</ser:ping>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>',
                {
                    timeout: 4500
                }
                )
                .then((response) => {
                    return Promise.resolve(response.status === 200);
                }).catch(() => {
                    return Promise.resolve(false);
                });
        };

        Vue.prototype.$searchFracciones = function (opts) {
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:obtenerFracciones>' +
                '<nombreRegional>' + opts.regional + '</nombreRegional>' +
                '<nombreColonia>' + opts.colonia + '</nombreColonia>' +
                '<nroInmueble>' + opts.inmueble + '</nroInmueble>' +
                '<nroFraccion>' + opts.fraccion + '</nroFraccion>' +
                '<nroColono>' + opts.nroColono + '</nroColono>' +
                '<nombreColono>' + opts.nombreColono + '</nombreColono>' +
                '</ser:obtenerFracciones>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>'
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:obtenerFraccionesResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);

                if (obj.ok) {
                    return Promise.resolve(obj.obj);
                } else {
                    return Promise.reject(obj);
                }
            });
        };

        Vue.prototype.$getInventario = function (id) {
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:obtenerNuevoInventario>' +
                '<idFraccion>' + id + '</idFraccion>' +
                '</ser:obtenerNuevoInventario>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>'
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:obtenerNuevoInventarioResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);

                if (obj.ok) {
                    return Promise.resolve(obj.obj);
                } else {
                    return Promise.reject(obj);
                }
            });
        };

        Vue.prototype.$setInventario = function (fraccion) {
            const data =
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:guardarInventario>' +
                '<i>' +
                JSON.stringify(fraccion) +
                '</i>' +
                '</ser:guardarInventario>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>';

            console.log(data);

            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                data,
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                
                    }
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:guardarInventarioResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);

                if (obj.ok) {
                    return Promise.resolve(obj.obj);
                } else {
                    return Promise.reject(new Error(JSON.stringify(obj)));
                }
            });
        };

        Vue.prototype.$modifyInventario = function (fraccion) {
            const data =
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:modificarInventario>' +
                '<i>' +
                JSON.stringify(fraccion) +
                '</i>' +
                '</ser:modificarInventario>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>';

            console.log(data);

            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                data,
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                
                    }
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:modificarInventarioResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);

                if (obj.ok) {
                    return Promise.resolve(obj.obj);
                } else {
                    return Promise.reject(new Error(JSON.stringify(obj)));
                }
            });
        };

        Vue.prototype.$modifiedInventario = function (fraccion) {
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:obtenerMejorasModificadas>' +
                '<i>' +
                JSON.stringify(fraccion) +
                '</i>' +
                '</ser:obtenerMejorasModificadas>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>',
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                 
                    }
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:obtenerMejorasModificadasResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);

                return Promise.resolve(obj);
            });
        };


        Vue.prototype.$getFoto = function (path) {
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:obtenerFoto>' +
                '<rutaFoto>' +
                path +
                '</rutaFoto>' +
                '</ser:obtenerFoto>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>',
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                
                    },
                    timeout: 12000
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');
//                console.log("Respuesta obtenerFoto: " + JSON.stringify(response));

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var obj = select("//soap:Envelope/soap:Body/ns2:obtenerFotoResponse/return/obj/text()", xmlDoc)[0].textContent;
//                console.log(obj);
                var okText = select("//soap:Envelope/soap:Body/ns2:obtenerFotoResponse/return/*", xmlDoc)[1].textContent;
//                console.log(okText);
        
                if (okText === 'true') {
                    return Promise.resolve(obj);
                } else {
                    return Promise.reject(new Error("Bajando " + path + ": " + JSON.stringify(obj)));
                }
            });
        };

        Vue.prototype.$setFoto = function (opts) {
            console.log("Subiendo foto " + opts.nombreFoto + " de fraccion " + opts.idFraccion);
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:guardarFoto>' +
                '<idFraccion>' + opts.idFraccion + '</idFraccion>' +
                '<nombreFoto>' + opts.nombreFoto + '</nombreFoto>' +
                '<foto>' + opts.foto + '</foto>' +
                '</ser:guardarFoto>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>',
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                
                    },
                    timeout: 12000
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                console.log("Respuesta setFoto: " + response.data);

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var obj = select("//soap:Envelope/soap:Body/ns2:guardarFotoResponse/return/obj/text()", xmlDoc)[0].textContent;
                var okText = select("//soap:Envelope/soap:Body/ns2:guardarFotoResponse/return/*", xmlDoc)[1].textContent;
        
                console.log("Respuesta setFoto: " + okText + ", " + obj);

                if (okText === "true") {
                    return Promise.resolve(obj);
                } else {
                    return Promise.reject(new Error(JSON.stringify(obj)));
                }
            });
        };

        Vue.prototype.$getDatosOperativos = function () {
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:obtenerDatosOperativos>' +
                '</ser:obtenerDatosOperativos>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>',
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                
                    }
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:obtenerDatosOperativosResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);
        
                if (obj.ok) {
                    return Promise.resolve(obj.obj);
                } else {
                    return Promise.reject(new Error(JSON.stringify(obj)));
                }
            });
        };

        Vue.prototype.$moverMejora = function (opts) {
            return this.axios.post(
                userOptions.soapServer + userOptions.soapUri,
                '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<ser:moverMejora>' +
                '<idFraccionOri>' + opts.fracOri + '</idFraccionOri>' +
                '<idFraccionDest>' + opts.fracDest + '</idFraccionDest>' +
                '<idMejora>' + opts.idMejora + '</idMejora>' +
                '<geoInfo>' + opts.geoInfo + '</geoInfo>' +
                '<usuario>' + opts.usuario + '</usuario>' +
                '</ser:moverMejora>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>',
                {
                    headers: {
                        'Content-Type': 'text/plain;charset=UTF-8'                 
                    }
                }
            ).then((response) => {
                var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

                var select = xpath.useNamespaces({
                    "soap": "http://schemas.xmlsoap.org/soap/envelope/",
                    "ns2": "http://services.business.sgdc.inc.sofis.com/"
                });
                var res = select("//soap:Envelope/soap:Body/ns2:moverMejoraResponse/return/text()", xmlDoc)[0].textContent;
                var obj = JSON.parse(res);
        
                console.log(obj);
                
                if (obj.ok) {
                    return Promise.resolve(obj.obj);
                } else {
                    return Promise.reject(new Error(JSON.stringify(obj)));
                }
            });
        };

Vue.prototype.$login = function (user, pass) {
    return this.axios.post(
        userOptions.soapServer + userOptions.soapUri,
        '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.business.sgdc.inc.sofis.com/">' +
        '<soapenv:Header/>' +
        '<soapenv:Body>' +
        '<ser:login>' +
        '<usuario>' + user + '</usuario>' +
        '<pass>' + pass + '</pass>' +
        '</ser:login>' +
        '</soapenv:Body>' +
        '</soapenv:Envelope>',
        {
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8'                
            }
        }
    ).then((response) => {
        var xmlDoc = new DOMParser().parseFromString(response.data, 'text/xml');

        var select = xpath.useNamespaces({
            "soap": "http://schemas.xmlsoap.org/soap/envelope/",
            "ns2": "http://services.business.sgdc.inc.sofis.com/"
        });
        var res = select("//soap:Envelope/soap:Body/ns2:loginResponse/return/text()", xmlDoc)[0].textContent;
        var obj = JSON.parse(res);

//        console.log(obj);
        
        if (obj.ok) {
            return Promise.resolve(obj.obj);
        } else {
            return Promise.reject(new Error(JSON.stringify(obj)));
        }
    });
};

    }
}

export default soap;
